<template>
  <div>
    <navigation msg="搜索"></navigation>
    <van-search
      v-model="value"
      show-action
      placeholder="请输入搜索关键词"
      @search="onSearch"
    >
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <div class="xxc">
      <div @click="isShow()">入离职状态</div>
      <div class="tms">
        <div @click="isShow()">请选择认证状态</div>
        <van-icon  @click="isShow()" size=".3rem" name="arrow" />
      </div>
    </div>
    <van-action-sheet cancel-text="取消" v-model="show" :actions="actions" @select="onSelect" />
  </div>
</template>
<script>
//  import {Search } from 'vant'
import navigation from "../../components/navigation/index"
export default {
  components:{
    navigation
  },
  data(){
    return {
       show:false,
       actions: [{ name: "入职",status:2 },{name: "离职",status:3}],
       value:""
    }
  },
  methods:{
    onSearch(){
      let value = this.value
       this.$router.push({path:"./companyList",query:{company_name :value}})
    },
    onSelect(value){
      this.$router.push({path:"./companyList",query:{status:value.status}})
    },
    isShow(){
      this.show = true
    },
    text(e){
      console.log(e)
    },
  }
}
</script>
<style scoped>
.xxc {
  margin-top:0.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 0.32rem 0.46rem;
  margin-bottom: 1px;
}
.xxc > div:first-child {
  font-size: 0.3rem;
  font-weight: 400;
  color: #333333;
  line-height: 0.48rem;
}
.tms {
  display: flex;
  align-items: center;
}
.tms > div:first-child {
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #848484;
  line-height: 0.48rem;
  margin-right: 0.2rem;
}
</style>